@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Inter", sans-serif;
}

.tooltip:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 1.625rem;
  margin-left: -0.425rem;
  border-width: 0.425rem;
  border-style: solid;
  border-color: #404040 transparent transparent transparent;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #6366f1;
  border-radius: 9999px;
  border-style: solid;
  border-color: transparent;
  border-width: 0.25rem;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  background: #6366f1d0;
  border-radius: 9999px;
  border-style: solid;
  border-color: transparent;
  border-width: 0.25rem;
  background-clip: padding-box;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
